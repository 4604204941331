<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="filter-box">
                <div class="input-option">
                    <el-date-picker v-model="value" type="date" value-format="yyyy-MM-dd HH:mm:ss" :editable="false"
                        :clearable="false" placeholder="选择日期">
                    </el-date-picker>
                </div>
                <el-button type="primary" size="small" @click="search" class="fb-search"
                    v-right-code="'/syslog/getsysloglist'">搜索</el-button> <br />
            </div>
            
            <p slot="elList">
                <el-table ref="sysLogListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>

                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))"  show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='OperationAction'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="" label="操作URL" ></el-table-column> -->
                </el-table>
            </p>
        </fixed-list>


    </div>
</template>
<script>
import df from '../../../../../scripts/displayformat.js';
    export default {
        mounted() {
            this.value = df.FormatDate(new Date(), 'yyyy-MM-dd hh:mm:ss');
            //this.Event.$on("reloadSysLogPageList", () => this.reloadPageList());
            
            this.initialize();
        },
        data() {
            return {
                value: '',
                multipleSelection: [],

                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },

            }
        },

        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {}
        },
        methods: {

            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                param.PageParams = { SearchLogTime: _this.value };
                this.queryParam = param;
             
                this.$ajax.query("omsapi/syslog/getsysloglist", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            search() {
            this.initialize();
        },
            rowClick(row) {
                this.$ajax.send("omsapi/syslog/getsyslogbyid", "get", { id: row.Id , date: this.value }, (data) => {
                    this.Event.$emit("clearEditSysLogForm");
                    this.onChangeEditDataSource(data.Result);
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            }

        }

    }
</script>
<style>
</style>